import posthog, { PostHog } from "posthog-js";
import { PostHogProvider as Provider } from "posthog-js/react";
import { ReactNode, useEffect, useState } from "react";

export type PostHogProviderProps = {
  children: ReactNode;
};

type State = {
  postHogClient: PostHog | undefined;
};

const postHogApiKey = import.meta.env.VITE_POSTHOG_API_KEY;

export default function PostHogProvider({ children }: PostHogProviderProps) {
  const [state, setState] = useState<State>({ postHogClient: undefined });

  useEffect(() => {
    if (postHogApiKey !== undefined && state.postHogClient === undefined) {
      posthog.init(postHogApiKey, { api_host: "/ingest" });

      setState((s) => ({ ...s, postHogClient: posthog }));
    }
  }, [state.postHogClient]);

  return state.postHogClient === undefined ? children : (
    <Provider client={state.postHogClient}>{children}</Provider>
  );
}
